import type { UnlikelyVideo } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { TVideo } from "~/components/ui/Video"

export function serializeShopifyVideos(videos: UnlikelyVideo[]): TVideo[] {
  return videos.reduce<TVideo[]>((acc, video) => {
    if (!video) {
      return acc
    }

    const serializedVideo = serializeVideo(video)
    if (serializedVideo) {
      acc.push(serializedVideo)
    }

    return acc
  }, [])
}

export function serializeVideo(item: Nullish<UnlikelyVideo>): Nullish<TVideo> {
  if (!item) return null

  return {
    poster: item.poster?.url ?? undefined,
    sources: item.sources.map((source) => ({ src: source.url, width: source.width, height: source.height })),
    alt: item.poster?.altText ?? "",
  }
}
