import type { TShopTheLookSchema } from "~/components/ui/ShopTheLookPanel/_data/schema"

export type TShopTheLook = ReturnType<typeof serializeSBShopTheLook>

export function serializeSBShopTheLook({ products }: TShopTheLookSchema) {
  return products
    ?.map((product) => {
      switch (product.component) {
        case "shopify_product_with_variant":
          return product.data?.data?.product?.id ?? null

        case "shopify_product_bundle_with_variant":
          return product.master_product?.data?.product?.id ?? null

        case "shopify_product_condensed":
          return product?.product?.data?.id ?? null
      }
    })
    ?.filter(Boolean)
}
