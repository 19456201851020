import type { Nullish } from "~/@types/generics"
import { getSfProduct } from "~/lib/shopify/queries/get-sf-product"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import type {
  MaterialPanelSimpleLinkFromSchema,
  ShopifyProductCondensedFromSchema,
} from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/_data/schema"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"

export type TMaterialPanelLink = ReturnType<typeof serializeLink>

export async function serializeMaterialPanelLink(
  link: ShopifyProductCondensedFromSchema | MaterialPanelSimpleLinkFromSchema,
  locale: Nullish<string>
): Promise<TMaterialPanelLink> {
  if ("product" in link) {
    const product = link?.product
    const productId = product?.data?.id
    // Get product reference if it exists
    if (productId) {
      const { product } = await getSfProduct({ id: productId, locale })

      const parsedLink = handleZodSafeParse(linkSchema, product, {
        errorContext: `InlineCta Product Link: ${product?.handle}`,
        locale,
      })

      return parsedLink ? serializeLink(parsedLink, locale) : null
    }
  }

  if ("link" in link) {
    return serializeLink(link.link, locale)
  }

  return null
}
