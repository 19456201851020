import { env } from "~/env"

export async function getStoryblokCV() {
  const response = await fetch(
    `https://api.storyblok.com/v2/cdn/spaces/me/?cv=CURRENT_TIMESTAMP&token=${env.NEXT_PUBLIC_STORYBLOK_TOKEN}`,
    { next: { revalidate: false, tags: ["storyblok", "cv"] } }
  )?.then((response) => response?.json() as Promise<{ space: { version: number } }>)

  return response?.space?.version
}
