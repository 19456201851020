import { z } from "zod"

import { optionalTupleSchema } from "~/lib/zod/utils/optional-tuple-schema"
import { sbIntegrationFieldProductSchema } from "~/lib/zod/utils/sb-integration-field-schema"
import { imageSchema } from "~/components/ui/Image/_data/schema"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import { richtextSchema } from "~/components/ui/RichText/_data/schema"

export const shopifyProductCondensedSchema = z.object({
  component: z.literal("shopify_product_condensed"),
  product: sbIntegrationFieldProductSchema,
})
const materialPanelSimpleLinkSchema = z.object({ link: linkSchema })

const materialPanelLinkSchema = z.union([
  optionalTupleSchema(materialPanelSimpleLinkSchema),
  optionalTupleSchema(shopifyProductCondensedSchema),
])

export type MaterialPanelLinkFromSchema = z.infer<typeof materialPanelLinkSchema>
export type ShopifyProductCondensedFromSchema = z.infer<typeof shopifyProductCondensedSchema>
export type MaterialPanelSimpleLinkFromSchema = z.infer<typeof materialPanelSimpleLinkSchema>

const materialItem = z.object({
  suptitle: z.string(),
  title: richtextSchema,
  text: richtextSchema,
  material_panel_link: materialPanelLinkSchema.nullish(),
  image: imageSchema,
})

export const panelMaterialsSchema = z.object({
  items: z.array(materialItem).nullish(),
})

export type PanelMaterialsFromSchema = z.infer<typeof panelMaterialsSchema>
