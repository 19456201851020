import { env } from "~/env"
import StoryblokClient from "storyblok-js-client"

import { getRevalidateTags } from "~/lib/constants"

export const Storyblok = new StoryblokClient({
  accessToken: env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  cache: {
    clear: "manual",
    type: "none",
  },
  fetch: (url, init) =>
    fetch(url, {
      ...init,
      next: { tags: getRevalidateTags("storyblok"), revalidate: false },
    }),
})

export const StoryblokNoCache = new StoryblokClient({
  accessToken: env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  cache: {
    clear: "manual",
    type: "none",
  },
  fetch: (url, init) =>
    fetch(url, {
      ...init,
      cache: "no-cache",
    }),
})

export default Storyblok
